import * as API from '@api/general';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { BranchDataFormik } from '@lib/hooks/formik/useBranchDataFormik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
//Components
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Input } from '../Input';
import { BRANCH_STEP } from './BranchForm';

const StyledForm = styled.div`
  width: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  @media only screen and ${breakpoints.maxWidth.md} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column-reverse;
    margin-top: 20px;
    button:first-child {
      margin-bottom: 0 !important;
    }
  }
`;
const StyledRow = styled.div`
  margin-left: -10px;
  margin-right: -10px;
`;
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 10px;

  ${({ size }) =>
    size === 2 &&
    css`
      float: left;
      width: 20%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 50%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 50%;
      }
    `}
  ${({ size }) =>
    size === 3 &&
    css`
      float: left;
      width: 20.5%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 50%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 50%;
      }
    `}
      ${({ size }) =>
    size === 7 &&
    css`
      float: left;
      width: 59.5%;
      @media only screen and ${breakpoints.maxWidth.md} {
        width: 100%;
      }
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 100%;
      }
    `}
`;

const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    font-size: 20px;
    padding: 10px 18px;
    margin-bottom: 20px;
  }
`;

const StyledFormSeperateTitle = styled.h4`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 24px;
  line-height: 1.2em;
  color: ${(props) => props.theme.colors.black};
  margin-top: 30px;
  margin-bottom: 18px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
`;

interface IColType {
  size?: number;
}

interface Props {
  formik: BranchDataFormik;
  setStep: React.Dispatch<React.SetStateAction<BRANCH_STEP>>;
}

export const BranchData = ({ formik, setStep }: Props) => {
  const { formatMessage: t } = useIntl();
  const [file, setFile] = useState(t({ id: 'select_data' }));
  const handleBack = () => setStep(BRANCH_STEP.BRANCH_CATEGORY);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      setFile(e.target.files[0].name);
      const res = await API.uploadMedia(formData);
      formik.setFieldValue('logo', res.id);
      formik.setFieldTouched('logo');
    } catch (error) {
      //@ts-ignore
      formik.setFieldError('logo', error.response.data.data.message);
    }
  };

  return (
    <StyledForm className="BranchData">
      <Form onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledCol>
            <Input
              type="file"
              name="logo"
              id="logo"
              label={t({ id: 'company_logo' })}
              labelInfo={t({ id: 'company_logo' })}
              className="form-control-custom input-image"
              fileText={file}
              invalid={!!formik.errors.logo && formik.touched.logo}
              onChange={handleFileChange}
              error={formik.errors.logo}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="company"
              id="company"
              label={t({ id: 'company' })}
              className="form-control-custom"
              value={formik.values.company}
              invalid={!!formik.errors.company && formik.touched.company}
              onChange={formik.handleChange}
              error={formik.errors.company}
            />
          </StyledCol>
          <StyledCol size={7}>
            <Input
              type="text"
              name="street"
              id="street"
              label={t({ id: 'street' })}
              className="form-control-custom"
              value={formik.values.street}
              invalid={!!formik.errors.street && formik.touched.street}
              onChange={formik.handleChange}
              error={formik.errors.street}
            />
          </StyledCol>
          <StyledCol size={3}>
            <Input
              type="text"
              name="postcode"
              id="postcode"
              label={t({ id: 'postcode' })}
              className="form-control-custom"
              value={formik.values.postcode}
              invalid={!!formik.errors.postcode && formik.touched.postcode}
              onChange={formik.handleChange}
              error={formik.errors.postcode}
            />
          </StyledCol>
          <StyledCol size={2}>
            <Input
              type="text"
              name="ort"
              id="ort"
              disabled
              label={t({ id: 'ort' })}
              labelInfo={t({ id: 'ort' })}
              className="form-control-custom"
              value={formik.values.ort}
              invalid={!!formik.errors.ort && formik.touched.ort}
              onChange={formik.handleChange}
              error={formik.errors.ort}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="website"
              id="website"
              label={t({ id: 'website' })}
              className="form-control-custom"
              value={formik.values.website}
              invalid={!!formik.errors.website && formik.touched.website}
              onChange={formik.handleChange}
              error={formik.errors.website}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="email"
              name="email"
              id="email"
              label={t({ id: 'email' })}
              className="form-control-custom"
              value={formik.values.email}
              invalid={!!formik.errors.email && formik.touched.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="email"
              name="email_confirmation"
              id="email_confirmation"
              label={t({ id: 'email_adresse_wiedeholen' })}
              className="form-control-custom"
              value={formik.values.email_confirmation}
              invalid={!!formik.errors.email_confirmation && formik.touched.email_confirmation}
              onChange={formik.handleChange}
              error={formik.errors.email_confirmation}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="phone"
              id="phone"
              label={t({ id: 'phone' })}
              className="form-control-custom"
              value={formik.values.phone}
              invalid={!!formik.errors.phone && formik.touched.phone}
              onChange={formik.handleChange}
              error={formik.errors.phone}
            />
          </StyledCol>
          <StyledCol>
            <StyledFormSeperateTitle>{t({ id: 'company_details' })}</StyledFormSeperateTitle>
          </StyledCol>
          <StyledCol>
            <Input
              type="textarea"
              name="company_profile"
              id="company_profile"
              label={t({ id: 'company_profile' })}
              labelInfo={t({ id: 'company_profile' })}
              className="form-control-custom"
              value={formik.values.company_profile}
              invalid={!!formik.errors.company_profile && formik.touched.company_profile}
              onChange={formik.handleChange}
              error={formik.errors.company_profile}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="textarea"
              name="credentials"
              id="credentials"
              label={t({ id: 'credentials' })}
              labelInfo={t({ id: 'credentials' })}
              className="form-control-custom"
              value={formik.values.credentials}
              invalid={!!formik.errors.credentials && formik.touched.credentials}
              onChange={formik.handleChange}
              error={formik.errors.credentials}
            />
          </StyledCol>
          <StyledCol>
            <Input
              type="text"
              name="languages"
              id="languages"
              label={t({ id: 'language' })}
              className="form-control-custom"
              value={formik.values.languages}
              invalid={!!formik.errors.languages && formik.touched.languages}
              onChange={formik.handleChange}
              error={formik.errors.languages}
            />
          </StyledCol>
          <StyledCol>
            <Input
              optional
              type="text"
              name="vimeo_url"
              id="vimeo_url"
              label={t({ id: 'vimeo_url' })}
              className="form-control-custom"
              value={formik.values.vimeo_url}
              invalid={!!formik.errors.vimeo_url && formik.touched.vimeo_url}
              onChange={formik.handleChange}
              error={formik.errors.vimeo_url}
            />
          </StyledCol>

          <StyledCol>
            <StyledButtonWrapper>
              <StyledButton color="black" onClick={handleBack}>
                {t({ id: 'button_back' })}
              </StyledButton>
              <StyledButton disabled={formik.isSubmitting} color="black">
                {formik.isSubmitting ? t({ id: 'loading' }) : t({ id: 'button_next_step' })}
              </StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledForm>
  );
};
