import * as API from '@api/branch';
import { Container } from '@components/shared/Container';
import HandleFetchingState from '@components/shared/HandleFetchingState';
import { BranchForm, BRANCH_STEP } from '@components/user/Branch/BranchForm';
import { useBranchCategoryFormik } from '@lib/hooks/formik/useBranchCategoryFormik';
import { useBranchContactFormik } from '@lib/hooks/formik/useBranchContactFormik';
import { useBranchContactSecondaryFormik } from '@lib/hooks/formik/useBranchContactSecondaryFormik';
import { useBranchDataFormik } from '@lib/hooks/formik/useBranchDataFormik';
import useGetBranchEdit from '@lib/hooks/queries/useGetBranchEdit';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

const StyledEditBranch = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.violet};
  padding: 120px 0;
`;

export const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  h1 {
    font-family: 'GTFlexa-ExtendedMedium', sans-serif;
    font-size: 58px;
    line-height: 1.05em;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 27px;
  }
  p {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.18em;
    color: ${(props) => props.theme.colors.black};
  }
`;

interface Props {
  slug: string;
}

export const EditBranch = (props: RouteComponentProps<Props>) => {
  const slug = props.match.params.slug;
  const { data, isLoading, error } = useGetBranchEdit(slug);

  return (
    <HandleFetchingState loading={isLoading} error={error}>
      <EditBranchWrapper data={data} slug={slug} />
    </HandleFetchingState>
  );
};

const EditBranchWrapper = ({ data, slug }: { data: API.GetBranchEditResponse | undefined; slug: string }) => {
  const [step, setStep] = useState(BRANCH_STEP.BRANCH_CATEGORY);
  const branchCategoryFormik = useBranchCategoryFormik({
    initialValues: {
      production_types: data?.branch.categories?.branch_categories?.map((el) => el.term_id),
      new_production_types: '',
      eco_certifications: data?.branch.categories?.branch_zusatzqualifikation
        ? data?.branch.categories?.branch_zusatzqualifikation?.map((el) => el.term_id)
        : [],
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.branchStep1Edit({
          step: '1',
          slug: slug,
          production_types: values.production_types,
          new_production_types: values.new_production_types,
          eco_certifications: values.eco_certifications,
        });
        setStep(res.step);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });
  const branchDataFormik = useBranchDataFormik({
    initialValues: {
      logo: 0,
      company: data?.branch?.company_details?.company_profile || '',
      street: data?.branch?.street || '',
      postcode: data?.branch?.postcode || '',
      ort: 'Wien',
      website: data?.branch?.website || '',
      email: data?.branch?.email || '',
      email_confirmation: data?.branch?.email || '',
      phone: data?.branch?.phone || '',

      company_profile: data?.branch?.company_details?.company_profile || '',
      credentials: data?.branch?.company_details?.credentials || '',
      languages: data?.branch?.company_details?.languages || '',
      vimeo_url: data?.branch?.company_details?.vimeo_url || '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.branchStep2Edit({
          step: '2',
          slug: slug,
          logo: values.logo,
          company: values.company,
          street: values.street,
          postcode: values.postcode,
          website: values.website,
          email: values.email,
          email_confirmation: values.email_confirmation,
          phone: values.phone,
          company_profile: values.company_profile,
          credentials: values.credentials,
          languages: values.languages,
          vimeo_url: values.vimeo_url,
        });
        setStep(res.step);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });
  const branchContactFormik = useBranchContactFormik({
    initialValues: {
      first_name: data?.branch?.contact_person?.first_name || '',
      last_name: data?.branch?.contact_person?.last_name || '',
      contact_person_email: data?.branch?.contact_person?.email || '',
      contact_person_email_confirmation: data?.branch?.contact_person?.email || '',
      contact_person_phone: data?.branch?.contact_person?.phone || '',
      contact_person_mobile: data?.branch?.contact_person?.mobile || '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.branchStep3Edit({
          step: '3',
          slug: slug,
          first_name: values.first_name,
          last_name: values.last_name,
          contact_person_email: values.contact_person_email,
          contact_person_email_confirmation: values.contact_person_email_confirmation,
          contact_person_phone: values.contact_person_phone,
          contact_person_mobile: values.contact_person_mobile,
        });
        setStep(res.step);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });
  const branchContactSecondaryFormik = useBranchContactSecondaryFormik({
    initialValues: {
      further_person_first_name: data?.branch?.further_person?.first_name || '',
      further_person_last_name: data?.branch?.further_person?.last_name || '',
      further_person_email: data?.branch?.further_person?.email || '',
      further_person_email_confirmation: data?.branch?.further_person?.email || '',
      further_person_phone: data?.branch?.further_person?.phone || '',
      further_person_mobile: data?.branch?.further_person?.mobile || '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.branchStep4Edit({
          step: '4',
          slug: slug,
          further_person_first_name: values?.further_person_first_name,
          further_person_last_name: values?.further_person_last_name,
          further_person_email: values.further_person_email,
          further_person_email_confirmation: values.further_person_email_confirmation,
          further_person_phone: values.further_person_phone,
          further_person_mobile: values.further_person_mobile,
        });
        setStep(BRANCH_STEP.THANK_YOU);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });
  return (
    <StyledEditBranch className="EditBranch">
      <Container>
        <BranchForm
          step={step}
          branchCategoryFormik={branchCategoryFormik}
          branchDataFormik={branchDataFormik}
          branchContactFormik={branchContactFormik}
          branchContactSecondaryFormik={branchContactSecondaryFormik}
          setStep={setStep}
        />
      </Container>
    </StyledEditBranch>
  );
};
