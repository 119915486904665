import { LocationItem } from '@api/locations';
import Module00Button from '@components/modules/Module00';
import ContentSlider from '@components/shared/ContentSlider';
import { useLocationBookmarksContext } from '@lib/context/LocationBookmarksContext/LocationBookmarksContext';
import useGetLocationsBySlug from '@lib/hooks/queries/useGetLocationsBySlug';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.violet};
   color: #ffffff; !important;
  .flickity-button svg path {
    stroke: #ffffff;
  }
`;

interface IMyBookmarksProps {
  title?: string;
  description?: string;
  allLocations?: Array<LocationItem>;
  bookmarkedLocations?: Array<string>;
}

const mapToLocationItem = (post: any) => ({
  ID: post.id,
  post_title: post.title,
  post_type: 'locations',
  post_content: post.description,
  description: post.description,
  thumbnail: post.thumbnail,
  post_slug: post.slug,
  link: `/locations/${post.slug}`,
});

const MyBookmarks: FC<IMyBookmarksProps> = (props) => {
  const { data } = useGetLocationsBySlug(props.bookmarkedLocations ?? []);
  const { removeBookmark } = useLocationBookmarksContext();
  const { formatMessage } = useIntl();
  const posts = (data as any)?.data?.map((item: LocationItem) => mapToLocationItem(item)) ?? [];

  return (
    <Wrapper id="my-bookmarks">
      <ContentSlider
        showDeleteIcon
        posts={posts as any}
        pre_title={props.title}
        title={props.description}
        hideAllButton
        whiteIcons
        backgroundColor="9D446E"
        onDeleteItem={(post) => {
          removeBookmark(post?.post_slug);
        }}
      />
      <Module00Button
        data={{
          link: {
            title: formatMessage({ id: 'sammelanfrage.zeigen' }),
            url: '/request-private-locations',
          },
        }}
      />
    </Wrapper>
  );
};

export default MyBookmarks;
