import { ReactComponent as Search } from '@assets/icons/icons_search_small.svg';
import { Link, Nav, SubNav } from '@components/header/Header';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { useAuthContext } from '@lib/context/AuthContext/AuthContext';
import { Language, useReactIntlProvider } from '@lib/context/ReactIntlProvider/ReactIntlProvider';
import { useUIContext } from '@lib/context/UIContext/UIContext';
import useGetPagesGeneralInfo from '@lib/hooks/queries/useGetPagesGeneralInfo';
import { ObjectAny } from '@lib/interfaces';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';

const MobileMenuWrapper = styled.div`
  width: 100%;
  height: ${window.innerHeight}px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: auto;
  background-color: #000000;
  //transform: translateX(-100%);
  //display: none;
  //&.open {
  //  display: block;
  //transform: translateX(0vmin);
  //}
`;

const MobileMenuTop = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 2px solid #ffffff;
  position: absolute;
  top: 0;
  background-color: #000000;
`;

const MobileMenuTopItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;

  &:first-of-type {
    border-right: 2px solid #ffffff;
  }
`;

const StyledActiveLang = styled.div`
  font-size: 22px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.36;
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchIcon = styled(Search)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  * {
    stroke: white !important;
  }
`;

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  height: calc(100% - 205px);
  overflow: auto;
`;

const MobileMenuItem = styled.a<{ isActive?: boolean }>`
  text-transform: uppercase;
  cursor: pointer;
  font-size: 22px;
  font-weight: normal;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.36;
  background-color: ${(props) => (props.isActive ? props.theme.colors.red : 'inherit')};
  border-bottom: ${(props) => (props.isActive ? '2px solid #000000' : '2px solid transparent')};
  color: ${(props) => (props.isActive ? props.theme.colors.black : props.theme.colors.white)};
  padding: 17px;
`;

const LoginMenuItem = styled(Button)`
  text-transform: uppercase;
  cursor: pointer;
  font-size: 22px;
  font-weight: normal;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.36;
  padding: 17px;
  background-color: transparent !important;
  border: none;
  color: #ffffff;
  display: block;
  text-align: left;

  &:hover {
    border: none !important;
  }

  &:before {
    display: block;
    content: '';
    width: 30px;
    height: 2px;
    background-color: #ffffff;
    position: relative;
    left: 0;
    margin-top: 15px;
    margin-bottom: 30px;
  }
`;

const MobileSubMenu = styled.div`
  background-color: ${(props) => props.theme.colors.red};
  display: flex;
  flex-direction: column;
  padding: 12px 40px; 
}
`;

export const SubLink = styled(RouterLink)`
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  padding: 10px 0;
  font-size: 22px;
  font-weight: normal;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: 1.25;
  color: ${(props) => props.theme.colors.black};
`;

const ButtonsWrapper = styled.div<{ menuOpened?: boolean }>`
  display: flex;
  flex-direction: column;
  border-top: 2px solid #ffffff;
  width: 100%;

  button {
    font-size: 22px;
    line-height: 1.36;
    padding: 10px 10px 10px 60px;
    text-align: left;

    @media only screen and ${breakpoints.maxWidth.md} {
      padding: 10px;
      text-align: center;
    }

    @media only screen and ${breakpoints.maxWidth.xs} {
      text-align: center;
    }

    &:last-of-type {
      &:hover {
        color: #000000;
        background-color: #ffffff !important;
      }
    }
  }
`;
const StyledLanguage = styled.div`
  width: 100%;
`;

export const scrollToFooter = () => {
  const footer = document.querySelector('footer');
  if (!footer) {
    return;
  }
  footer.scrollIntoView({
    behavior: 'smooth',
  });
};

const availableLanguages = [
  { value: Language.DE, label: 'deutsch' },
  {
    value: Language.EN,
    label: 'english',
  },
];

interface Props {
  showDropdownOnTop?: boolean;
}

export default function HeaderMobile(props: Props) {
  const { isAuthenticated } = useAuthContext();
  const uiContext = useUIContext();
  const history = useHistory();
  const { data } = useGetPagesGeneralInfo();
  const languageRef = useRef<any>(null);
  const { activeLanguage, setActiveLanguage } = useReactIntlProvider();

  const closeMenu = () => {
    uiContext.setState({ ...uiContext, isMobileMenuOpened: false });
  };

  const openLanguage = () => {
    uiContext.setState({ ...uiContext, languageOpened: true });
  };

  const closeLanguage = () => {
    uiContext.setState({ ...uiContext, languageOpened: false });
  };

  const { formatMessage: t } = useIntl();

  const contactButton = data?.header?.right_side_buttons?.second_button;
  const loginButton = data?.header?.right_side_buttons?.first_button;

  const navItems = data?.header?.menu ?? [];

  useEffect(() => {
    // document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const [active, setActive] = useState<number | undefined>(undefined);

  const handleOnClick = (id: number) => {
    if (active === id) {
      setActive(undefined);
      return;
    }
    setActive(id);
  };

  const subItems = (navItems as ObjectAny).find((item: ObjectAny) => item.ID === active)?.children ?? [];
  const leftPositionValue = languageRef?.current?.getBoundingClientRect()?.left;

  return (
    <MobileMenuWrapper style={{ height: `${window.innerHeight}px` }}>
      <MobileMenuTop>
        <MobileMenuTopItem onMouseLeave={closeLanguage}>
          <StyledLanguage ref={languageRef}>
            <StyledActiveLang onMouseEnter={openLanguage}>{activeLanguage}</StyledActiveLang>

            <SubNav isMobile open={uiContext.state.languageOpened} showOnTop={props.showDropdownOnTop} leftPadding={leftPositionValue - 110}>
              <Nav isMobile>
                {availableLanguages.map((lang) => {
                  return (
                    <Link
                      to=""
                      key={lang.value}
                      active={activeLanguage === lang.value ? 'true' : undefined}
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveLanguage(lang.value);
                      }}
                    >
                      {lang.label}
                    </Link>
                  );
                })}
              </Nav>
            </SubNav>
          </StyledLanguage>
        </MobileMenuTopItem>
        <MobileMenuTopItem onClick={() => uiContext.setState({ isMobileMenuOpened: false, isSearchOpened: true })}>
          <SearchIcon />
        </MobileMenuTopItem>
      </MobileMenuTop>

      <MobileMenu>
        {navItems.map((item: ObjectAny) => (
          <Fragment key={item.ID}>
            <MobileMenuItem
              isActive={active === item.ID}
              onClick={(e) => {
                e.preventDefault();

                if (item.children?.length > 0) {
                  handleOnClick(item?.ID);
                  return;
                }

                history.push(item.slug);
              }}
            >
              {item.title}
            </MobileMenuItem>

            {active === item.ID && (
              <MobileSubMenu>
                {subItems.map((item: ObjectAny) => (
                  <SubLink key={`sub-item-${item.ID}`} to={`/${item.slug}`}>
                    {item.title}
                  </SubLink>
                ))}
              </MobileSubMenu>
            )}
          </Fragment>
        ))}
        {isAuthenticated ? (
          <LoginMenuItem to={'/profile'}>{'Profile'}</LoginMenuItem>
        ) : (
          <LoginMenuItem to={loginButton?.url ?? '/login'}>{loginButton?.title ?? t({ id: 'login' })}</LoginMenuItem>
        )}
      </MobileMenu>

      <ButtonsWrapper>
        <Button
          color="black"
          onClick={() => {
            closeMenu();
            scrollToFooter();
          }}
        >
          {contactButton?.title ?? t({ id: 'kontakt' })}
        </Button>

        <Button onClick={() => uiContext.setState({ isMobileMenuOpened: false })}>
          {uiContext.state.isMobileMenuOpened ? t({ id: 'menu.schließen' }) : t({ id: 'menu' })}
        </Button>
      </ButtonsWrapper>
    </MobileMenuWrapper>
  );
}
