import * as API from '@api/locations';
import Button from '@components/shared/Button';
import breakpoints from '@lib/common/breakpoints';
import { useLocationDeleteFormik } from '@lib/hooks/formik/useLocationDeleteFormik';
import { useRouter } from '@lib/hooks/useRouter';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Checkbox } from '../Checkbox';
import { Input } from '../Input';

const StyledLocationDeleteForm = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 120px 150px;
`;
const StyledFormTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  h3 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-right: 20px;
  }
  a {
    font-family: 'GTFlexa-StandardMedium';
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.24em;
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
    margin-bottom: 5px;
  }
`;
const StyledRow = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;

interface IColType {
  size?: number;
}
const StyledCol = styled.div<IColType>`
  display: inline-block;
  width: 100%;
  padding: 0 16px;
  ${({ size }) =>
    size === 6 &&
    css`
      float: left;
      width: 50%;
    `}
`;
const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
`;
const StyledCancelButton = styled(Button)`
  min-width: 341px;
  font-size: 24px;
  padding: 24px 36px;
  margin-right: 30px;
`;
const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 24px 36px;
`;
const StyledFormSeperateTitle = styled.h4`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 24px;
  line-height: 1.2em;
  color: ${(props) => props.theme.colors.black};
  margin-top: 30px;
  margin-bottom: 24px;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
`;

interface Props {
  location?: API.GetLocationResponse;
  setDeletedSuccessfully: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LocationDeleteForm = (props: Props) => {
  const { formatMessage: t } = useIntl();
  const router = useRouter();

  const formik = useLocationDeleteFormik({
    initialValues: {
      id: props.location?.id || 0,
      no_longer_use: false,
      too_much_effort: false,
      dont_want_to_show: false,
      other_reason: '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.deleteLocations(values);
        props.setDeletedSuccessfully(true);
        formikHelpers.resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <StyledLocationDeleteForm className="LocationDeleteForm">
      <StyledFormTitle>
        <h3>{t({ id: 'location_data' })}</h3>
        <Link to={`/manage-location/${props.location?.slug}/edit`}>{t({ id: 'do_you_only_want_to_edit_this_location' })}</Link>
      </StyledFormTitle>
      <Form onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledCol>
            <Input
              type="text"
              name="title"
              id="title"
              label={t({ id: 'location_name' })}
              className="form-control-custom"
              disabled
              value={props.location?.title}
            />
          </StyledCol>
          <StyledCol>
            <StyledFormSeperateTitle>{t({ id: 'reason_for_deletion' })}</StyledFormSeperateTitle>
          </StyledCol>
          <StyledCol>
            <Checkbox
              name="no_longer_use"
              id="no_longer_use"
              label={t({ id: 'my_location_is_no_longer_used' })}
              checked={formik.values.no_longer_use}
              invalid={!!formik.errors.no_longer_use && formik.touched.no_longer_use}
              onChange={formik.handleChange}
              error={formik.errors.no_longer_use}
            />
          </StyledCol>
          <StyledCol>
            <Checkbox
              name="too_much_effort"
              id="too_much_effort"
              label={t({ id: 'too_much_effort' })}
              checked={formik.values.too_much_effort}
              invalid={!!formik.errors.too_much_effort && formik.touched.too_much_effort}
              onChange={formik.handleChange}
              error={formik.errors.too_much_effort}
            />
          </StyledCol>
          <StyledCol>
            <Checkbox
              name="dont_want_to_show"
              id="dont_want_to_show"
              label={t({ id: 'i_don_t_want_to_specify' })}
              checked={formik.values.dont_want_to_show}
              invalid={!!formik.errors.dont_want_to_show && formik.touched.dont_want_to_show}
              onChange={formik.handleChange}
              error={formik.errors.dont_want_to_show}
            />
          </StyledCol>

          <StyledCol>
            <Input
              type="textarea"
              name="other_reason"
              id="other_reason"
              label={t({ id: 'other_reason' })}
              className="form-control-custom"
              value={formik.values.other_reason}
              invalid={!!formik.errors.other_reason && formik.touched.other_reason}
              onChange={formik.handleChange}
              error={formik.errors.other_reason}
            />
          </StyledCol>
          <StyledCol>
            <StyledButtonWrapper>
              <StyledCancelButton onClick={() => router.history.push(`/locations/${props.location?.slug}`)}>
                {t({ id: 'private_request_button_back' })}
              </StyledCancelButton>
              <StyledButton disabled={formik.isSubmitting} color="black">
                {formik.isSubmitting ? t({ id: 'loading' }) : t({ id: 'delete_location' })}
              </StyledButton>
            </StyledButtonWrapper>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledLocationDeleteForm>
  );
};
