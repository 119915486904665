import { ReactComponent as ArrowDown } from '@assets/icons/icons_arrow-down.svg';
import { ReactComponent as ArrowUp } from '@assets/icons/icons_arrow-up.svg';
import { Container } from '@components/shared/Container';
import Image from '@components/shared/Image';
import breakpoints from '@lib/common/breakpoints';
import prepareLink from '@lib/helpers/prepareLink';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { default as ReactSlick } from 'react-slick';
import styled from 'styled-components';

const Module09Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.violet};
  border-top: 2px solid #ffffff;
`;

const InnerWrapper = styled.div`
  display: flex;

  @media only screen and ${breakpoints.maxWidth.xs} {
    flex-direction: column;
  }
`;

const GridItem = styled.div`
  width: 50%;
  border-right: 2px solid #ffffff;
  padding: 80px;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    border-right: none;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 40px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    width: 100%;
    border-right: none;
    padding: 20px;
  }
`;

const Post = styled.div`
  max-width: 557px;
  margin: 0 auto;
  @media only screen and ${breakpoints.maxWidth.md} {
    max-width: 308px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    max-width: 100%;
  }
`;

const Thumbnail = styled(Image)`
  width: 100%;
  height: 386px;
  margin-bottom: 24px;
  object-fit: contain;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 213px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    height: 234px;
  }
`;

const Text = styled.h1`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;

  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 16px;
    line-height: 1.25;
  }
`;

const SmallText = styled.p`
  font-family: 'GTFlexa-ExtendedMedium', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 0.17px;
  color: #ffffff;
  @media only screen and ${breakpoints.maxWidth.md} {
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: 0.2px;
  }
`;

const Slider = styled(ReactSlick)`
  &.slick-slider {
    height: calc(100% - 159px) !important;

    @media only screen and ${breakpoints.maxWidth.md} {
      height: calc(100% - 90px) !important;
    }
  }

  .slick-track {
    height: auto !important;
  }

  .slick-list {
    height: 100% !important;
    flex-grow: 1;
    padding-top: 80px;

    @media only screen and ${breakpoints.maxWidth.md} {
      padding-top: 40px;
    }

    @media only screen and ${breakpoints.maxWidth.sm} {
      padding-top: 40px;
    }

    &:after {
      content: '';
      width: 100%;
      height: 125px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(to top, #9d446e, rgba(255, 0, 0, 0));
      @supports (-webkit-backdrop-filter: blur(1px)) {
        display: none;
      }
    }
  }
`;

const SliderItem = styled.div`
  width: 100%;
  max-width: 556px;
  margin-bottom: 45px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and ${breakpoints.maxWidth.sm} {
    max-width: 100%;
  }
`;

const SliderLink = styled(RouterLink)`
  display: inline-block;
  &:hover {
    p,
    h1 {
      color: ${(props) => props.theme.colors.red} !important;
    }
  }
`;
const SliderGridContainer = styled(GridItem)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
  height: 663px;

  @media only screen and ${breakpoints.maxWidth.md} {
    height: 450px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    height: 400px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    border-top: 2px solid #ffffff;
  }
`;

const SliderControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 48px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding-bottom: 20px;
  }
`;

const StyledArrowButton = styled.button`
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;

  &:disabled {
    opacity: 0.5;
    svg {
      transform: none !important;
    }
  }

  &:first-of-type {
    margin-right: 40px;
  }

  svg {
    width: 37px;
    height: 38px;
    transition: 0.1s;
    transform-origin: center;
    &:hover {
      transform: scale(0.8);
    }
    path {
      stroke: #ffffff;
    }
  }
`;

interface Module09Props {
  data?: {
    pinned_production?: any;
    other_productions?: any;
  };
}

export default function Module09(props: Module09Props) {
  const slickRef = useRef<any>(null);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);

  const sliderLength = props?.data?.other_productions?.length ?? 0;

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    vertical: true,
    center: false,
    verticalSwiping: true,
    beforeChange: (current: any, next: any) => {
      if (sliderLength - 3 === next) {
        setDisableNext(true);
      } else {
        setDisableNext(false);
      }

      if (next > 0) {
        setDisablePrev(false);
      } else {
        setDisablePrev(true);
      }
    },
  };

  const handlePrevClick = () => {
    if (slickRef.current) {
      slickRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (slickRef.current) {
      slickRef.current.slickNext();
    }
  };

  const pinnedItem = props?.data?.pinned_production;
  return (
    <Module09Container data-module="m09">
      <Container>
        <InnerWrapper>
          <GridItem>
            <Post>
              <RouterLink to={prepareLink(pinnedItem?.link)}>
                <Thumbnail src={pinnedItem?.thumbnail} />
                <Text style={{ marginBottom: 9 }}>{pinnedItem?.post_title}</Text>
                <SmallText>{pinnedItem?.preview_description}</SmallText>
              </RouterLink>
            </Post>
          </GridItem>
          <SliderGridContainer>
            <Slider ref={slickRef} {...settings}>
              {props?.data?.other_productions?.map((item: any, index: number) => {
                return (
                  <SliderItem key={`${item?.ID}-${index}`}>
                    <SmallText style={{ color: 'rgba(255, 255, 255, 0.5)' }}>{item?.post_type}</SmallText>
                    <SliderLink to={prepareLink(prepareLink(item?.link))}>
                      <Text style={{ marginTop: 10, marginBottom: 5 }}>{item?.post_title} </Text>
                      <SmallText>{item?.preview_description}</SmallText>
                    </SliderLink>
                  </SliderItem>
                );
              })}
            </Slider>
            <SliderControls>
              <StyledArrowButton disabled={disablePrev} onClick={handlePrevClick}>
                <ArrowUp />
              </StyledArrowButton>
              <StyledArrowButton disabled={disableNext} onClick={handleNextClick}>
                <ArrowDown />
              </StyledArrowButton>
            </SliderControls>
          </SliderGridContainer>
        </InnerWrapper>
      </Container>
    </Module09Container>
  );
}
