import { ReactComponent as SearchSVG } from '@assets/icons/icons_search_small.svg';
import { Link, Nav, SubNav } from '@components/header/Header';
import { Language, useReactIntlProvider } from '@lib/context/ReactIntlProvider/ReactIntlProvider';
import { useUIContext } from '@lib/context/UIContext/UIContext';
import { useRef } from 'react';
import styled from 'styled-components';

const SearchAndLanguageLayout = styled.div`
  display: flex;
  align-items: center;
  margin-left: 18px;
  flex: 1;

  &:before {
    content: '';
    width: 2px;
    height: 20px;
    background-color: ${(props) => props.theme.colors.white};
    margin-right: 30px;

    @media only screen and (max-width: 1405px) {
      margin-right: 15px;
    }
  }

  > span {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 20px 0;
  }
`;

const Search = styled.span`
  margin-left: 11px;
  flex: 1;
`;

const SearchIcon = styled(SearchSVG)`
  width: 25px;
  height: 25px;
  cursor: pointer;

  @media only screen and (max-width: 1405px) {
    width: 20px;
    height: 20px;
  }
  * {
    stroke: white !important;
  }
`;

const StyledLanguage = styled.span<{ active?: boolean }>`
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.active ? props.theme.colors.red : null)};
  border-bottom: 1px solid #000000;

  > span {
    color: ${(props) => (props.active ? '#000000' : '#ffffff')};
  }
`;

const StyledActiveLang = styled.span`
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  font-family: 'GTFlexa-ExtendedLight', sans-serif;
  line-height: ${(props) => props.theme.helpers.pxToRem(30)};
  color: ${(props) => props.theme.colors.white};

  @media only screen and (max-width: 1405px) {
    font-size: 18px;
  } ;
`;

const availableLanguages = [
  { value: Language.DE, label: 'deutsch' },
  {
    value: Language.EN,
    label: 'english',
  },
];

interface SearchAndLanguageProps {
  showDropdownOnTop?: boolean;
}

export default function SearchAndLanguage(props: SearchAndLanguageProps) {
  const uiContext = useUIContext();
  const languageRef = useRef<any>(null);
  const { activeLanguage, setActiveLanguage } = useReactIntlProvider();

  const openLanguage = () => {
    uiContext.setState({ ...uiContext, languageOpened: true });
  };

  const closeLanguage = () => {
    uiContext.setState({ ...uiContext, languageOpened: false });
  };

  const leftPositionValue = languageRef?.current?.getBoundingClientRect()?.left;

  return (
    <SearchAndLanguageLayout onMouseLeave={closeLanguage}>
      <StyledLanguage ref={languageRef} active={uiContext.state.languageOpened}>
        <StyledActiveLang onMouseEnter={openLanguage}>{activeLanguage}</StyledActiveLang>

        <SubNav open={uiContext.state.languageOpened} showOnTop={props.showDropdownOnTop} leftPadding={leftPositionValue - 110}>
          <Nav>
            {availableLanguages.map((lang) => {
              return (
                <Link
                  to=""
                  key={lang.value}
                  active={activeLanguage === lang.value ? 'true' : undefined}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveLanguage(lang.value);
                  }}
                >
                  {lang.label}
                </Link>
              );
            })}
          </Nav>
        </SubNav>
      </StyledLanguage>
      <Search onClick={() => uiContext.setState({ isSearchOpened: true })}>
        <SearchIcon />
      </Search>
    </SearchAndLanguageLayout>
  );
}
