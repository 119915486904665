import * as API from '@api/filming';
import breakpoints from '@lib/common/breakpoints';
import { useFilmingFormik } from '@lib/hooks/formik/useFilmingFormik';
import { StyledPageHeader } from '@pages/Filming';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useIsMutating, useMutation } from 'react-query';
import styled, { css } from 'styled-components';
import { FilmingApplicant } from './FilmingApplicant';
import { FilmingComplete } from './FilmingComplete';
import { FilmingLocation } from './FilmingLocation';
import { FilmingProduction } from './FilmingProduction';
import { FilmingShootingTime } from './FilmingShootingTime';

const StyledForm = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 80px 150px 120px;
  @media only screen and ${breakpoints.maxWidth.md} {
    padding: 80px 24px 50px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding: 80px 24px 50px;
  }
`;
const StyledFormTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  @media only screen and ${breakpoints.maxWidth.md} {
    align-items: flex-start;
    flex-direction: column;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    align-items: flex-start;
    flex-direction: column;
  }
  h3 {
    font-family: 'GTFlexa-ExtendedLight', sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.black};
    margin-right: 20px;
    @media only screen and ${breakpoints.maxWidth.md} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 24px;
      letter-spacing: 0.2px;
      margin-right: 0;
    }
  }
  a {
    font-family: 'GTFlexa-StandardMedium';
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.24em;
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
    margin-bottom: 5px;
    @media only screen and ${breakpoints.maxWidth.md} {
      margin-top: 5px;
    }
    @media only screen and ${breakpoints.maxWidth.sm} {
      margin-top: 5px;
    }
  }
`;

const StyledStepDots = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
`;

interface IStyledStepDot {
  filled?: boolean;
}
const StyledStepDot = styled.div<IStyledStepDot>`
  width: 13px;
  height: 13px;
  border: 2px solid ${(props) => props.theme.colors.black};
  margin-right: 30px;
  @media only screen and ${breakpoints.maxWidth.md} {
    width: 8px;
    height: 8px;
    margin-right: 20px;
  }
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 8px;
    height: 8px;
    margin-right: 20px;
  }
  &:last-child {
    margin-right: 0;
  }
  ${({ filled }) =>
    filled &&
    css`
      background-color: ${(props) => props.theme.colors.black};
    `}
`;

export enum FILMING_STEP {
  APPLICANT = 1,
  PRODUCTION = 2,
  SHOOTING_TIME = 3,
  LOCATION = 4,
  THANK_YOU = 5,
}

interface Props {
  data?: API.FilmingFormDataResponse;
}

const mutationKey = 'filming-form';

export const FilmingForm = (props: Props) => {
  const { formatMessage: t } = useIntl();
  const [step, setStep] = useState(FILMING_STEP.APPLICANT);

  const mutation = useMutation(API.submitFilming, {
    mutationKey: mutationKey,
  });
  const isMutating = useIsMutating(mutationKey);

  const steps = [
    {
      name: `1. ${t({ id: 'first_step_title' })}`,
    },
    {
      name: `2. ${t({ id: 'second_step_title' })}`,
    },
    {
      name: `3. ${t({ id: 'third_step_title' })}`,
    },
    {
      name: `4. ${t({ id: 'fourth_step_title' })}`,
    },
    {
      name: t({ id: 'filming_success' }),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const formik = useFilmingFormik({
    onSubmit: async (values, formikHelpers) => {
      if (isMutating) {
        return;
      }

      try {
        await mutation.mutateAsync({
          ...values,
          number_of_people_on_crew: values.number_of_people_on_crew === '0' ? 'zero' : values.number_of_people_on_crew,
          number_of_people_cast: values.number_of_people_cast === '0' ? 'zero' : values.number_of_people_cast,
          type_of_production: values.type_of_production.map((el: number) => el - 1),
        });

        // await API.submitFilming({  });

        setStep(step + 1);
      } catch (error) {
        //@ts-ignore
        formikHelpers.setErrors(error.response.data.data.errors);
      }
    },
  });

  const renderActiveStep = () => {
    switch (step) {
      case FILMING_STEP.APPLICANT:
        return <FilmingApplicant setStep={setStep} formik={formik} />;
      case FILMING_STEP.PRODUCTION:
        return (
          <FilmingProduction
            setStep={setStep}
            formik={formik}
            type_of_production={
              props.data?.data.type_of_production?.map((el, index) => {
                return { label: el.type, value: index + 1 };
              }) || []
            }
          />
        );
      case FILMING_STEP.SHOOTING_TIME:
        return <FilmingShootingTime setStep={setStep} formik={formik} />;
      case FILMING_STEP.LOCATION:
        return <FilmingLocation setStep={setStep} formik={formik} privacy_text={props.data?.data.privacy_text} />;
      case FILMING_STEP.THANK_YOU:
        return <FilmingComplete setStep={setStep} />;
      default:
        return null;
    }
  };

  return (
    <>
      <StyledPageHeader>
        <h1>{t({ id: 'drehgenehmigung_title1' })}</h1>
        <p>{t({ id: 'drehgenehmigung_title2' })}</p>
      </StyledPageHeader>
      <StyledForm className="FilmingForm">
        {step !== FILMING_STEP.THANK_YOU && (
          <StyledStepDots>
            {steps
              .filter((s, i) => i !== steps.length - 1)
              .map((el, index) => {
                return <StyledStepDot key={`filming-form-${index}`} filled={index + 1 <= step} />;
              })}
          </StyledStepDots>
        )}
        <StyledFormTitle>
          <h3>{steps[step - 1]?.name}</h3>
        </StyledFormTitle>

        {renderActiveStep()}
      </StyledForm>
    </>
  );
};
