import LayoutWithHeaderFooter from '@components/layout/LayoutWithHeaderFooter';
import { PageType } from '@lib/interfaces/Pages';
import { ConfirmPostDelete } from '@pages/ConfirmPostDelete';
import { Filming } from '@pages/Filming';
import ForgotPassword from '@pages/ForgotPassword';
import Home from '@pages/Home';
import { Login } from '@pages/Login';
import { CreateBranch } from '@pages/manage-branch/CreateBranch';
import { EditBranch } from '@pages/manage-branch/EditBranch';
import { CreateLocation } from '@pages/manage-location/CreateLocation';
import { DeleteLocation } from '@pages/manage-location/DeleteLocation';
import { EditLocation } from '@pages/manage-location/EditLocation';
import { Page404 } from '@pages/Page404';
import { Profile } from '@pages/profile/Profile';
import { ProfileChangePassword } from '@pages/profile/ProfileChangePassword';
import { ProfileDelete } from '@pages/profile/ProfileDelete';
import { ProfileEdit } from '@pages/profile/ProfileEdit';
import { Register } from '@pages/Register';
import { RequestPrivateLocation } from '@pages/RequestPrivateLocation';
import { RequestPrivateLocations } from '@pages/RequestPrivateLocations';
import ResetPassword from '@pages/ResetPassword';
import Search from '@pages/Search';
import BranchesDetails from '@pages/wordpress-pages/BranchesDetails';
import FilmingPermitsDetails from '@pages/wordpress-pages/FilmingPermitsDetails';
import LocationDetails from '@pages/wordpress-pages/LocationDetails';
import NewsDetails from '@pages/wordpress-pages/NewsDetails';
import ProductionDetails from '@pages/wordpress-pages/ProductionDetails';
import WordpressPage from '@pages/WordpressPage';
import { RouteProps, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { RestrictedRoute } from './RestrictedRoute';

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED,
}

export interface AppRoute extends RouteProps {
  type?: RouteType;
  layout: {
    component: any;
    hideHeader?: boolean;
    hideFooter?: boolean;
  };
}

const RESTRICTED_ROUTES: AppRoute[] = [
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: PageType.LOGIN,
    component: Login,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: PageType.FORGOT_PASSWORD,
    component: ForgotPassword,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: PageType.RESET_PASSWORD,
    component: ResetPassword,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: PageType.REGISTER,
    component: Register,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
];

const PRIVATE_ROUTES: AppRoute[] = [
  {
    type: RouteType.PRIVATE,
    path: PageType.PROFILE,
    exact: true,
    component: Profile,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.PRIVATE,
    path: PageType.PROFILE_EDIT,
    exact: true,
    component: ProfileEdit,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.PRIVATE,
    path: PageType.PROFILE_DELETE,
    exact: true,
    component: ProfileDelete,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.PRIVATE,
    path: PageType.CHANGE_PASSWORD,
    component: ProfileChangePassword,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: PageType.MANAGE_LOCATION_CREATE,
    component: CreateLocation,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: PageType.MANAGE_LOCATION_EDIT,
    component: EditLocation,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: PageType.MANAGE_LOCATION_DELETE,
    component: DeleteLocation,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.PRIVATE,
    path: PageType.MANAGE_BRANCH_CREATE,
    component: CreateBranch,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: PageType.MANAGE_BRANCH_EDIT,
    component: EditBranch,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: PageType.MANAGE_BRANCH_DELETE,
    component: DeleteLocation,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
];

const PUBLIC_ROUTES: AppRoute[] = [
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: PageType.HOME,
    component: Home,
    layout: {
      component: LayoutWithHeaderFooter,
      hideHeader: true,
    },
  },
  {
    type: RouteType.PUBLIC,
    path: PageType.FILMING,
    component: Filming,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: PageType.REQUEST_PRIVATE_LOCATIONS,
    component: RequestPrivateLocations,
    layout: {
      component: LayoutWithHeaderFooter,
      hideHeader: false,
    },
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: PageType.REQUEST_PRIVATE_LOCATION,
    component: RequestPrivateLocation,
    layout: {
      component: LayoutWithHeaderFooter,
      hideHeader: false,
    },
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: PageType.SEARCH,
    component: Search,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: PageType.BRANCHES_DETAILS,
    component: BranchesDetails,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },

  {
    type: RouteType.PUBLIC,
    exact: true,
    path: PageType.LOCATIONS_DETAILS,
    component: LocationDetails,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: PageType.NEWS_DETAILS,
    component: NewsDetails,
    layout: {
      component: LayoutWithHeaderFooter,
      hideHeader: false,
    },
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: PageType.PRODUCTION_DETAILS,
    component: ProductionDetails,
    layout: {
      component: LayoutWithHeaderFooter,
      hideHeader: false,
    },
  },

  {
    type: RouteType.PUBLIC,
    exact: true,
    path: PageType.FILMING_PERMITS_DETAILS,
    component: FilmingPermitsDetails,
    layout: {
      component: LayoutWithHeaderFooter,
      // hideHeader: true,
    },
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: PageType.FILMING_PERMITS_DETAILS_EN,
    component: FilmingPermitsDetails,
    layout: {
      component: LayoutWithHeaderFooter,
      // hideHeader: true,
    },
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: PageType.POST_DELETE_CONFIRMATION,
    component: ConfirmPostDelete,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },

  //This route matches all declared pages in "@lib/constants/Pages".
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: PageType.WORDPRESS_PAGE,
    component: WordpressPage,
    layout: {
      component: LayoutWithHeaderFooter,
    },
  },
];

export const AppRoutes: AppRoute[] = [
  //=== TEMP ====//
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: PageType.PAGE_NOT_FOUND,
    component: Page404,
    layout: {
      component: LayoutWithHeaderFooter,
      hideFooter: true,
      hideHeader: true,
    },
  },

  ...RESTRICTED_ROUTES,
  ...PRIVATE_ROUTES,
  ...PUBLIC_ROUTES,
];

export const Routes = () => {
  // const lang = getLanguageFromLocalStorage();

  return (
    <Switch>
      {AppRoutes.map((r) => {
        const { type, path, ...rest } = r;

        const routePath = `/${r.path}`;

        if (type === RouteType.PRIVATE) {
          return <PrivateRoute {...rest} key={`${r.path}`} path={routePath} />;
        }

        if (type === RouteType.RESTRICTED) {
          return <RestrictedRoute {...rest} key={`${r.path}`} path={routePath} />;
        }

        return <PublicRoute {...rest} key={`${r.path}`} path={routePath} />;
      })}

      <PublicRoute component={Page404} />
    </Switch>
  );
};
