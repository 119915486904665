import objectToQueryString from '@lib/helpers/objectToQueryString';
import { BranchDeleteFields } from '@lib/hooks/formik/useBranchDeleteFormik';
import { ObjectAny, PaginatedData, PaginationParams } from '@lib/interfaces';
import { deleteRequest, get, ISelect, post } from './axios';

export interface BranchFormDataResponse {
  eco_certifications: ISelect[];
  production_types: ISelect[];
  step: number;
  error?: string;
}

export interface BranchStep1Response {
  step: number;
  message: string;
  error?: string;
}

export interface BranchStep1Input {
  step: '1';
  slug?: string;
  production_types: string;
  eco_certifications: string;
  new_production_types: string;
}
export interface BranchStep2Input {
  step: '2';
  slug?: string;
  logo: number;
  company: string;
  vimeo_url: string;
  street: string;
  postcode: string;
  website: string;
  email: string;
  email_confirmation: string;
  phone: string;
  company_profile: string;
  credentials: string;
  languages: string;
}
export interface BranchStep3Input {
  step: '3';
  slug?: string;
  first_name: string;
  last_name: string;
  contact_person_email: string;
  contact_person_email_confirmation: string;
  contact_person_phone: string;
  contact_person_mobile: string;
}
export interface BranchStep4Input {
  step: '4';
  slug?: string;
  further_person_first_name: string;
  further_person_last_name: string;
  further_person_email: string;
  further_person_email_confirmation: string;
  further_person_phone: string;
  further_person_mobile: string;
}

export interface BranchItem extends ObjectAny {
  id: number;
  title: string;
  thumbnail: string;
  slug: string;
  date: string;
  description: string;
  post_status: string;
  thumbnails: {
    thumbnail: string;
    medium: string;
    medium_large: string;
    large: string;
  };
}

export interface GetBranchesInput extends PaginationParams {
  branch_zusatzqualifikation?: string;
}

export interface GetRelatedBranchesInput extends PaginationParams {
  slug?: string;
}

export interface GetBranchesResponse extends PaginatedData {
  branches: Array<BranchItem>;
}
export interface GetMyBranchesResponse extends PaginatedData {
  branches: Array<BranchItem>;
}

export interface GetBranchResponse {
  id: number;
  title: string;
  slug: string;
  author_id: string;
  thumbnail: string;
  thumbnails: {
    thumbnail: string;
    medium: string;
    medium_large: string;
    large: string;
  };
  date: string;
  categories: {
    branch_categories: ISelect[];
    branch_zusatzqualifikation: ISelect[];
  };
  street: string;
  postcode: string;
  location: string;
  website: string;
  email: string;
  phone: string;
  description: string;
  description_text?: string;
  company_details: {
    company_profile: string;
    vimeo_url: string;
    instagram_url: string;
    facebook_url: string;
    languages: string;
    credentials: string;
  };
  contact_person: {
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    phone: string;
  };
  further_person: {
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    phone: string;
  };
}

export interface GetBranchEditResponse {
  branch: GetBranchResponse;
  art: ISelect[];
  ausstattung: ISelect[];
  epochs: ISelect[];
  location_categories: ISelect[];
}

export interface DeleteBranchResponse {
  message?: string;
  error?: string;
}

export const branchStep1 = async (data: BranchStep1Input) => post<BranchStep1Input, BranchStep1Response>({ url: 'v1/branches/register', data });

export const branchStep2 = async (data: BranchStep2Input) => post<BranchStep2Input, BranchStep1Response>({ url: 'v1/branches/register', data });

export const branchStep3 = async (data: BranchStep3Input) => post<BranchStep3Input, BranchStep1Response>({ url: 'v1/branches/register', data });

export const branchStep4 = async (data: BranchStep4Input) => post<BranchStep4Input, BranchStep1Response>({ url: 'v1/branches/register', data });

export const getBranchFormData = async () => get<undefined, BranchFormDataResponse>({ url: 'v1/branches/form_data' });

export const branchStep1Edit = async (data: BranchStep1Input) => post<BranchStep1Input, BranchStep1Response>({ url: 'v1/branches/edit', data });

export const branchStep2Edit = async (data: BranchStep2Input) => post<BranchStep2Input, BranchStep1Response>({ url: 'v1/branches/edit', data });

export const branchStep3Edit = async (data: BranchStep3Input) => post<BranchStep3Input, BranchStep1Response>({ url: 'v1/branches/edit', data });

export const branchStep4Edit = async (data: BranchStep4Input) => post<BranchStep4Input, BranchStep1Response>({ url: 'v1/branches/edit', data });

export const getBranches = (params: GetBranchesInput) => {
  return get<GetBranchesInput, GetBranchesResponse>({ url: `v1/branches/all?${objectToQueryString(params)}` });
};

export const getRelatedBranches = ({ slug, ...rest }: GetRelatedBranchesInput) => {
  return get<GetRelatedBranchesInput, GetBranchesResponse>({ url: `v1/branches/${slug}/related?${objectToQueryString(rest)}` });
};

export const getBranchFilters = async (letter?: string) =>
  get<undefined, ObjectAny>({
    url: 'v1/branches/filters',
    params: {
      letter,
    },
  });

export const getBranchDetails = (slug?: string) => get<{ slug?: string }, GetBranchResponse>({ url: `v1/branches/${slug}/single` });

export const getMyBranches = () => {
  return get<undefined, GetMyBranchesResponse>({ url: `v1/branches/myBranches?${objectToQueryString({ showAll: -1 })}` });
};

export const deleteBranch = async (data: BranchDeleteFields) =>
  deleteRequest<BranchDeleteFields, DeleteBranchResponse>({ url: 'v1/branches/delete', data });

export const getBranchEdit = (slug?: string) => get<{ slug?: string }, GetBranchEditResponse>({ url: `v1/branches/${slug}/edit-data` });
