import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface BranchDataFields {
  logo: number;
  company: string;
  street: string;
  postcode: string;
  ort: string;
  website: string;
  email: string;
  email_confirmation: string;
  phone: string;

  company_profile: string;
  credentials: string;
  languages: string;
  vimeo_url: string;
}

interface UseBranchFormOptions {
  initialValues?: BranchDataFields;
  onSubmit: (values: BranchDataFields, formikHelpers: FormikHelpers<BranchDataFields>) => Promise<any>;
}

export const useBranchDataFormik = (options: UseBranchFormOptions) => {
  const BranchSchema = Yup.object().shape({
    logo: Yup.mixed().required('Logo is required'),
    company: Yup.string().required('Company is required'),
    street: Yup.string().required('Address is required'),
    postcode: Yup.string().required('PLZ is required'),
    ort: Yup.string().required('ORT is required'),
    website: Yup.string().required('Website is required'),
    email: Yup.string().email().required('Email is required'),
    email_confirmation: Yup.string().email().required('Email confirm is required'),
    phone: Yup.string().required('Phone is required'),
    company_profile: Yup.string().required('Contact person is required'),
    credentials: Yup.string().required('Credentials is required'),
    languages: Yup.string().required('Language is required'),
  });

  return useFormik({
    initialValues: {
      logo: 0,
      company: '',
      street: '',
      postcode: '',
      ort: 'Wien',
      website: '',
      email: '',
      email_confirmation: '',
      phone: '',

      company_profile: '',
      credentials: '',
      languages: '',
      vimeo_url: '',
      ...options.initialValues,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: BranchSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type BranchDataFormik = ReturnType<typeof useBranchDataFormik>;
