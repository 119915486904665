import LocationRequestInfo from '@components/locations/LocationRequestInfo';
import LocationsList from '@components/locations/LocationsList';
import MyBookmarks from '@components/locations/MyBookmarks';
import { Container } from '@components/shared/Container';
import Filters from '@components/shared/Filters';
import FullWidthSearch from '@components/shared/FullWidthSearch';
import LoadMoreButton from '@components/shared/LoadMoreButton';
import breakpoints from '@lib/common/breakpoints';
import { useLocationBookmarksContext } from '@lib/context/LocationBookmarksContext/LocationBookmarksContext';
import objectToStringParams from '@lib/helpers/objectToStringParams';
import removeObjectEmptyProps from '@lib/helpers/removeObjectEmptyProps';
import useGetLocations from '@lib/hooks/queries/useGetLocations';
import useGetLocationsFilters from '@lib/hooks/queries/useGetLocationsFilters';
import useElementPosition from '@lib/hooks/useElementPosition';
import useLocalStorageFilters from '@lib/hooks/useLocalStorageFilters';
import { ObjectAny } from '@lib/interfaces';
import ModuleType from '@lib/interfaces/Modules';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/esm';

const Wrapper = styled.div`
  position: relative;
`;

const LocationsListWrapper = styled.div<{ gutterTop?: boolean }>`
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  padding-top: ${(props) => (props.gutterTop ? '160px' : null)};
  margin-left: -27px;
  margin-right: -27px;

  @media only screen and ${breakpoints.maxWidth.md} {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: -16px;
    margin-right: -16px;
  }

  @media only screen and ${breakpoints.maxWidth.xs} {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-left: -5px;
    margin-right: -5px;
  }
`;

interface IModule31Props {
  className?: string;
  slug?: string;
  data?: {
    title?: string;
    sammelanfrage?: string;
    sammelanfrage_after?: string;
    sammelanfrage_before?: string;
  };

  searchProps?: {
    searchValue?: string;
    showResultsHeader?: boolean;
  };
}

const prepareFilterValues = (filters: ObjectAny) => {
  return {
    label: filters?.label,
    values: filters?.values?.reduce(
      (acc: any, next: any) => ({
        ...acc,
        [next['slug']]: next['name'],
      }),
      {},
    ),
  };
};

const Module31Inner: FC<IModule31Props> = (props) => {
  const ref = useRef(null);
  const position = useElementPosition(ref);

  const { getLocalStorageFilterByName, setLocalStorageFilter } = useLocalStorageFilters(ModuleType.MODULE_31);

  const [searchValue, setSearchValue] = useState(props.searchProps?.searchValue ?? '');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [selectedCategory, setSelectedCategory] = useState<string>(getLocalStorageFilterByName('selectedCategory', ''));
  const [selectedLetter, setSelectedLetter] = useState<string | undefined>(getLocalStorageFilterByName('selectedLetter', undefined));
  const [selectedFilters, setSelectedFilters] = useState<ObjectAny>(
    getLocalStorageFilterByName('selectedFilters', {
      branch_zusatzqualifikation: {},
      categories: [],
    }),
  );

  useEffect(() => {
    setLocalStorageFilter({
      selectedLetter,
      selectedCategory,
      selectedFilters,
      searchValue,
    });
  }, [selectedLetter, selectedCategory, selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSearchValue(props.searchProps?.searchValue ?? '');
  }, [props.searchProps?.searchValue]);

  const { bookmarks } = useLocationBookmarksContext();

  const locationsFilters = useGetLocationsFilters(selectedLetter, {
    onSuccess: (data1) => {
      if (selectedLetter === undefined) {
        setSelectedLetter(data1?.letters?.values?.[0] ?? 'A');
      }
    },
  });

  const letters = useMemo(() => locationsFilters?.data?.letters?.values ?? [], [locationsFilters?.data?.letters]);
  const categories = locationsFilters?.data?.categories ?? {};
  const epochs = locationsFilters?.data?.epochs ?? {};
  const location_art = locationsFilters?.data?.location_art ?? {};
  const location_ausstattung = locationsFilters?.data?.location_ausstattung ?? {};

  const { data, isFetching, hasNextPage, fetchNextPage } = useGetLocations(
    removeObjectEmptyProps({
      search: debouncedSearchValue,
      categories: selectedCategory,
      epochs: objectToStringParams(selectedFilters?.epochs),
      location_art: objectToStringParams(selectedFilters?.location_art),
      location_ausstattung: objectToStringParams(selectedFilters?.location_ausstattung),
    }),
  );

  const handleOnFilterChange = (keyEl: string, el: string) => {
    const isSelected = !!selectedFilters?.[keyEl]?.[el];

    const newFilters = selectedFilters ? { ...selectedFilters[keyEl], [el]: isSelected ? undefined : el } : { [el]: isSelected ? undefined : el };
    setSelectedFilters({
      ...selectedFilters,
      [keyEl]: { ...newFilters },
    });
  };

  const handleOnCategoriesChange = (category: string) => {
    setSelectedCategory(selectedCategory === category ? '' : category);

    if (!ref.current) {
      return;
    }
    const list = ref.current;

    (list as any).scrollIntoView({
      behavior: 'smooth',
    });
  };

  const handleOnSelectLetterChange = (letter?: string) => {
    setSelectedLetter(letter);
  };

  const allLocations = data?.pages?.map((item) => item.locations).flat() ?? [];
  const allLocationsWithBookmarks = allLocations?.map((item) => (bookmarks?.includes(item.slug) ? { ...item, bookmarked: true } : item));

  const isOnSearchPage = Boolean(props.searchProps);

  return (
    <Wrapper className={props.className} data-module={ModuleType.MODULE_31}>
      <Filters
        isOnSearch={isOnSearchPage}
        selectedLetter={selectedLetter}
        letters={letters}
        categories={{
          label: categories?.label,
          values: categories?.values?.map((item: any) => ({ label: item?.name, value: item?.slug, count: item?.count })) ?? [],
        }}
        selectedCategory={selectedCategory}
        selectedFilters={selectedFilters}
        filters={{
          epochs: prepareFilterValues(epochs),
          location_art: prepareFilterValues(location_art),
          location_ausstattung: prepareFilterValues(location_ausstattung),
        }}
        onFiltersChange={handleOnFilterChange}
        onCategoriesChange={handleOnCategoriesChange}
        onSelectLetterChange={handleOnSelectLetterChange}
      />

      {!isOnSearchPage && (
        <>
          <FullWidthSearch title={props.data?.title ?? ''} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />

          {isFetching || !bookmarks?.length ? (
            <LocationRequestInfo title={props.data?.sammelanfrage} description={props.data?.sammelanfrage_before} />
          ) : (
            <MyBookmarks
              title={props.data?.sammelanfrage}
              description={props.data?.sammelanfrage_after}
              allLocations={allLocations}
              bookmarkedLocations={bookmarks}
            />
          )}
        </>
      )}

      <InfiniteScroll dataLength={allLocationsWithBookmarks.length} next={fetchNextPage} hasMore={Boolean(hasNextPage)} loader={null}>
        <Container>
          <LocationsListWrapper ref={ref} gutterTop={!isOnSearchPage}>
            <LocationsList
              loading={isFetching}
              showResultsHeader={props.searchProps?.showResultsHeader}
              sticky={position?.top <= 20}
              hideBookmarkItems={!bookmarks?.length || isOnSearchPage}
              bookmarksNumber={bookmarks?.length}
              locations={allLocationsWithBookmarks}
            />
            {isFetching && <LoadMoreButton isLoading />}
          </LocationsListWrapper>
        </Container>
      </InfiniteScroll>
    </Wrapper>
  );
};

const Module31 = (props: IModule31Props) => {
  return <Module31Inner {...props} />;
};

export default Module31;
