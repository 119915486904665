import HandleFetchingState from '@components/shared/HandleFetchingState';
import useGetPage from '@lib/hooks/queries/useGetPage';
import useModules from '@lib/hooks/useModules';
import { PageType } from '@lib/interfaces/Pages';
import React, { FC } from 'react';
import { Redirect } from 'react-router';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

interface IDefaultProps {
  slug?: string;
}

const Default: FC<IDefaultProps> = (props) => {
  const { isLoading, data, error } = useGetPage(props.slug);

  // useRemoveNavigationHandler(data?.remove_navigation);

  const { getModuleByName } = useModules();

  const renderModules = () => {
    const blocks = data?.blocks ?? [];
    return blocks
      ?.map((block: any, index: number) => {
        const moduleProps = block?.attrs?.data;
        const Module = getModuleByName(block?.blockName);
        const sameProps = {
          key: `module-${block?.blockName}-${index}`,
          data: moduleProps,
        };

        if (!Module) {
          return null;
        }

        return <Module {...sameProps} />;
      })
      .filter((m: any) => Boolean(m));
  };

  //@ts-ignore
  if (!isLoading && !data && !error?.response?.data?.success) {
    return <Redirect to={PageType.PAGE_NOT_FOUND} />;
  }

  return (
    <HandleFetchingState loading={isLoading}>
      <Wrapper className={`page-${props.slug}`}>{renderModules()}</Wrapper>
    </HandleFetchingState>
  );
};

export default Default;
